import * as React from "react"

const TypeSharePromoCodeSvg = (props) => (
	<svg
		viewBox="0 0 62 32"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M53.981 0H6.2C2.799 0 0 2.799 0 6.2v18.593c0 3.401 2.799 6.2 6.2 6.2h47.781c3.401 0 6.2-2.799 6.2-6.2V6.2c0-3.401-2.799-6.2-6.2-6.2m3.1 24.793c0 1.701-1.399 3.1-3.1 3.1H6.2a3.114 3.114 0 0 1-3.1-3.1V6.2c0-1.701 1.399-3.1 3.1-3.1h47.781c1.701 0 3.1 1.399 3.1 3.1v18.593Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(.5 .5)"
		/>
		<path
			d="M6.2-.5h47.781c3.674.003 6.697 3.026 6.7 6.7v18.593c-.003 3.674-3.026 6.697-6.7 6.7H6.2c-3.674-.003-6.697-3.026-6.7-6.7V6.2C-.497 2.526 2.526-.497 6.2-.5Zm47.781 30.991c3.127 0 5.7-2.573 5.7-5.7V6.2c0-3.127-2.573-5.7-5.7-5.7H6.2C3.073.5.5 3.073.5 6.2v18.593c0 3.127 2.573 5.7 5.7 5.7l47.781-.002ZM6.2 2.6h47.781c1.975 0 3.6 1.625 3.6 3.6v18.593c0 1.975-1.625 3.6-3.6 3.6H6.2a3.617 3.617 0 0 1-3.6-3.6V6.2c0-1.975 1.625-3.6 3.6-3.6Zm47.781 24.792c1.426 0 2.6-1.174 2.6-2.6V6.2c0-1.426-1.174-2.6-2.6-2.6H6.2a2.613 2.613 0 0 0-2.6 2.6v18.593c0 1.426 1.174 2.6 2.6 2.6l47.781-.001Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(.5 .5)"
		/>
		<path
			d="M68.563 67.1a1.557 1.557 0 0 0-2.117-.567l-1.7.982V65.55c0-.85-.7-1.55-1.55-1.55-.85 0-1.55.7-1.55 1.55v1.964l-1.7-.982a1.55 1.55 0 0 0-.801-.223c-.85 0-1.55.7-1.55 1.55 0 .564.307 1.085.801 1.357l1.7.982-1.7.982c-.464.28-.749.785-.749 1.327 0 .85.7 1.55 1.55 1.55.262 0 .52-.066.749-.193l1.7-.982v1.964c0 .85.7 1.55 1.55 1.55.85 0 1.55-.7 1.55-1.55v-1.963l1.7.982c.236.137.504.209.777.209a1.56 1.56 0 0 0 1.551-1.552c0-.552-.295-1.065-.774-1.342l-1.7-.982 1.7-.982a1.55 1.55 0 0 0 .567-2.117"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(-48.102 -54.204)"
		/>
		<path
			d="M63.2 76.9a2.062 2.062 0 0 1-2.05-2.05v-1.1l-.95.55a2.045 2.045 0 0 1-.994.257 2.06 2.06 0 0 1-2.05-2.05c0-.719.378-1.387.994-1.757l.95-.55-.951-.549a2.054 2.054 0 0 1-1.079-1.805 2.06 2.06 0 0 1 2.05-2.05c.38 0 .752.105 1.075.304l.951.549v-1.1a2.06 2.06 0 0 1 2.05-2.05 2.06 2.06 0 0 1 2.05 2.05v1.1l.954-.549c.304-.169.646-.257.994-.257a2.06 2.06 0 0 1 2.05 2.05c0 .719-.378 1.387-.994 1.757l-.951.549.951.549a2.053 2.053 0 0 1 1.026 1.776 2.061 2.061 0 0 1-2.051 2.051A2.05 2.05 0 0 1 66.2 74.3l-.951-.549v1.1A2.063 2.063 0 0 1 63.2 76.9Zm-1.05-4.88v2.83c0 .576.474 1.05 1.05 1.05.576 0 1.05-.474 1.05-1.05v-2.83l2.45 1.412c.165.1.353.153.546.153.576 0 1.05-.474 1.05-1.05 0-.383-.21-.737-.546-.921L65.295 70.2l2.451-1.415c.324-.187.524-.535.524-.909 0-.576-.474-1.05-1.05-1.05h-.001c-.183 0-.363.049-.521.141l-2.453 1.413v-2.83c0-.576-.474-1.05-1.05-1.05-.576 0-1.05.474-1.05 1.05v2.83L59.7 66.965a1.052 1.052 0 0 0-.546-.153c-.576 0-1.05.474-1.05 1.05 0 .383.21.737.546.921L61.1 70.2l-2.451 1.415a1.05 1.05 0 0 0-.513.902c0 .576.474 1.05 1.05 1.05.179 0 .356-.046.512-.133l2.452-1.414Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(-48.102 -54.204)"
		/>
		<path
			d="M175.23 67.1a1.557 1.557 0 0 0-2.117-.567l-1.7.982V65.55c0-.85-.7-1.55-1.55-1.55-.85 0-1.55.7-1.55 1.55v1.964l-1.7-.982a1.55 1.55 0 0 0-.801-.223c-.85 0-1.55.7-1.55 1.55 0 .564.307 1.085.801 1.357l1.7.982-1.7.982c-.464.28-.749.785-.749 1.327 0 .85.7 1.55 1.55 1.55.262 0 .52-.066.749-.193l1.7-.982v1.964c0 .85.7 1.55 1.55 1.55.85 0 1.55-.7 1.55-1.55v-1.963l1.7.982c.242.146.519.223.801.223.85 0 1.55-.7 1.55-1.55a1.55 1.55 0 0 0-.801-1.357l-1.7-.982 1.7-.982a1.551 1.551 0 0 0 .568-2.117"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(-139.273 -54.204)"
		/>
		<path
			d="M169.862 76.9a2.062 2.062 0 0 1-2.05-2.05v-1.1l-.951.549a2.057 2.057 0 0 1-1.056.293 2.06 2.06 0 0 1-2.05-2.05c0-.744.405-1.432 1.056-1.793l.951-.549-.951-.549a2.053 2.053 0 0 1-1.026-1.776 2.06 2.06 0 0 1 2.05-2.05c.36 0 .713.095 1.025.275l.951.549v-1.1a2.06 2.06 0 0 1 2.05-2.05 2.06 2.06 0 0 1 2.05 2.05v1.1l.951-.549a2.057 2.057 0 0 1 1.056-.293 2.06 2.06 0 0 1 2.05 2.05c0 .744-.405 1.432-1.056 1.793l-.951.549.951.549c.616.37.994 1.038.994 1.757a2.06 2.06 0 0 1-2.05 2.05c-.348 0-.69-.088-.994-.257l-.951-.549v1.1a2.063 2.063 0 0 1-2.049 2.051Zm-1.05-4.88v2.83c0 .576.474 1.05 1.05 1.05.576 0 1.05-.474 1.05-1.05v-2.83l2.451 1.415c.165.1.353.153.546.153.576 0 1.05-.474 1.05-1.05 0-.383-.21-.737-.546-.921l-2.451-1.417 2.451-1.415a1.05 1.05 0 1 0-1.048-1.819l-2.451 1.415V65.55c0-.576-.474-1.05-1.05-1.05-.576 0-1.05.474-1.05 1.05v2.83l-2.451-1.415a1.054 1.054 0 0 0-1.433.383 1.049 1.049 0 0 0 .384 1.435l2.451 1.415-2.451 1.415a1.05 1.05 0 0 0-.513.902c0 .576.474 1.05 1.05 1.05.179 0 .356-.046.512-.133l2.449-1.412Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(-139.273 -54.204)"
		/>
		<path
			d="M281.9 67.1a1.557 1.557 0 0 0-2.117-.567l-1.7.982V65.55c0-.85-.7-1.55-1.55-1.55-.85 0-1.55.7-1.55 1.55v1.964l-1.7-.982a1.544 1.544 0 0 0-.749-.193c-.85 0-1.55.7-1.55 1.55 0 .542.285 1.047.749 1.327l1.7.982-1.7.982c-.464.28-.749.785-.749 1.327 0 .85.7 1.55 1.55 1.55.262 0 .52-.066.749-.193l1.7-.982v1.964c0 .85.7 1.55 1.55 1.55.85 0 1.55-.7 1.55-1.55v-1.963l1.7.982c.242.146.519.223.801.223.85 0 1.55-.7 1.55-1.55a1.55 1.55 0 0 0-.801-1.357l-1.7-.982 1.7-.982a1.55 1.55 0 0 0 .567-2.117"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(-230.444 -54.204)"
		/>
		<path
			d="M276.529 76.9a2.062 2.062 0 0 1-2.05-2.05v-1.1l-.951.549a2.045 2.045 0 0 1-.994.257 2.06 2.06 0 0 1-2.05-2.05c0-.719.378-1.387.994-1.757l.951-.549-.951-.549a2.052 2.052 0 0 1-1.045-1.787 2.06 2.06 0 0 1 2.05-2.05c.367 0 .728.099 1.044.286l.951.549v-1.1a2.06 2.06 0 0 1 2.05-2.05 2.06 2.06 0 0 1 2.05 2.05v1.1l.951-.549a2.057 2.057 0 0 1 1.056-.293 2.06 2.06 0 0 1 2.05 2.05c0 .744-.405 1.432-1.056 1.793l-.951.549.951.549c.616.37.994 1.038.994 1.757a2.06 2.06 0 0 1-2.05 2.05c-.348 0-.69-.088-.994-.257l-.951-.549v1.1a2.063 2.063 0 0 1-2.049 2.051Zm-1.05-4.88v2.83c0 .576.474 1.05 1.05 1.05.576 0 1.05-.474 1.05-1.05v-2.83l2.451 1.415c.166.103.358.158.553.158a1.05 1.05 0 0 0 .496-1.976l-2.451-1.417 2.451-1.415a1.05 1.05 0 0 0-1.05-1.819l-2.452 1.416V65.55c0-.576-.474-1.05-1.05-1.05-.576 0-1.05.474-1.05 1.05v2.83l-2.451-1.415a1.052 1.052 0 0 0-.546-.153c-.576 0-1.05.474-1.05 1.05 0 .383.21.737.546.921l2.451 1.415-2.451 1.415a1.05 1.05 0 0 0 1.05 1.819l2.453-1.412Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(-230.444 -54.204)"
		/>
	</svg>
)

export default TypeSharePromoCodeSvg
