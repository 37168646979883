import React from "react"
import PromoCodeHeaderComponent from "./components/PromoCodeHeaderComponent";
import PromoCodeHowItWorkComponent from "./components/PromoCodeHowItWorkComponent";
import DownloadSharePromoCodeSvg from "../../svgs/sharePromo/DownloadSharePromoCodeSvg";
import RegisterSharePromoCodeSvg from "../../svgs/sharePromo/RegisterSharePromoCodeSvg";
import ScanSharePromoCodeSvg from "../../svgs/sharePromo/ScanSharePromoCodeSvg";
import TypeSharePromoCodeSvg from "../../svgs/sharePromo/TypeSharePromoCodeSvg";

import PromoCodeBoxComponent from "./components/PromoCodeBoxComponent";
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from "../../constants";
import AppStoreSvg from "../../svgs/AppStoreSvg";
import AndroidSvg from "../../svgs/AndroidSvg";
import AppShopBtnComponent from "./components/AppShopBtnComponent";

const SharePromoCodePage = () => {
	return (
		<>
			<PromoCodeHeaderComponent />
			<PromoCodeBoxComponent />
			<section className="how-it-work">
				<div className="container">
					<h4 className="text-lg">Jak to działa?</h4>
					<div className="how-it-work-content-container">
						<PromoCodeHowItWorkComponent title="Pobierz aplikację Outlet Park Szczecin" Icon={DownloadSharePromoCodeSvg} index={0}>
							<AppShopBtnComponent Icon={AndroidSvg} to={GOOGLE_PLAY_LINK} title={"Google Play"} />
							<AppShopBtnComponent Icon={AppStoreSvg} to={APP_STORE_LINK} title={"App Store"} />
						</PromoCodeHowItWorkComponent>
						<PromoCodeHowItWorkComponent title="Zarejestruj się w aplikacji" Icon={RegisterSharePromoCodeSvg} index={1} />
						<PromoCodeHowItWorkComponent title="Zeskanuj pierwszy paragon" Icon={ScanSharePromoCodeSvg} index={2} />
						<PromoCodeHowItWorkComponent
							title="Wpisz swój kod referencyjny <br /> <br /> ... a następnie odbierz punkty, zbieraj kolejne punkty i ciesz się swoimi nagrodami!"
							Icon={TypeSharePromoCodeSvg}
							index={3}
						/>
					</div>
				</div>
			</section>
		</>
	);
};

export default React.memo(SharePromoCodePage);
