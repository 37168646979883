import React from "react"
import moment from 'moment';
import LogoPng from "../imgs/logo.png";
import LoaderComponent from "./LoaderComponent";
import { SUBTITLE_TEST_ID, TITLE_TEST_ID } from "../constants/testIds";

interface IMainContainerComponent {
	loading?: boolean
	title?: string
	subtitle?: string
	children: JSX.Element | JSX.Element[]
}

const MainContainerComponent = ({ title, subtitle, children, loading, }: IMainContainerComponent) => {

	return (
		<>
			<header className="container">
				<img src={LogoPng} alt="Outlet Park Szczecin" className="logo" />
			</header>
			<main className="container">
				{title && <h1 data-testid={TITLE_TEST_ID}>{title}</h1>}
				{subtitle && <h2 className="subtitle" data-testid={SUBTITLE_TEST_ID}>{subtitle}</h2>}
				{children}
			</main>
			<footer>
				<p className="copyrights">© Copyrights <span id="year">{moment().format('YYYY')}</span></p>
			</footer>
			<LoaderComponent loading={loading} />
		</>
	);
};

export default React.memo(MainContainerComponent);
