import * as React from "react"

const RegisterSharePromoCodeSvg = (props) => (
	<svg
		viewBox="0 0 40 48"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M21.239 21.149a10.93 10.93 0 0 1-3.829.693c-5.991 0-10.921-4.93-10.921-10.921S11.419 0 17.41 0c5.983 0 10.909 4.917 10.921 10.9a10.773 10.773 0 0 1-7.092 10.251m-3.8-16.789c-3.58.011-6.519 2.959-6.519 6.539 0 3.587 2.952 6.539 6.539 6.539 3.587 0 6.539-2.952 6.539-6.539 0-.712-.116-1.419-.344-2.093a6.481 6.481 0 0 0-6.212-4.446h-.003Zm-4.2 39.228H4.358v-8.721c0-2.391 1.967-4.358 4.358-4.358h11.245a1.84 1.84 0 0 0 1.831-1.831v-.6a1.936 1.936 0 0 0-1.925-1.925H8.718C3.936 26.154.001 30.088 0 34.87v10.904a2.19 2.19 0 0 0 2.175 2.179h11.041a2.05 2.05 0 0 0 2.039-2.039V45.6c0-1.106-.91-2.016-2.016-2.017m12.913 4.362h-6.539a2.19 2.19 0 0 1-2.179-2.179v-6.538a2.182 2.182 0 0 1 .632-1.548l9.657-9.657c2.382-2.45 6.236-2.797 9.018-.813a6.818 6.818 0 0 1 .766 10.3L27.7 47.313a2.18 2.18 0 0 1-1.547.636m-4.358-4.358h3.465l9.153-9.176a2.436 2.436 0 0 0 .745-1.753c0-1.336-1.1-2.436-2.436-2.436-.661 0-1.293.268-1.752.743l-9.176 9.153.001 3.469Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
		/>
	</svg>
)

export default RegisterSharePromoCodeSvg
