import React from "react"

interface IPromoCodeHowItWorkComponent {
	title: string
	index: number
	Icon: any
	children?: JSX.Element | JSX.Element[]
}

const PromoCodeHowItWorkComponent = ({ Icon, title, children, index }: IPromoCodeHowItWorkComponent) => {

	return (
		<section className="promo-code-how-it-work" data-aos="fade-up" data-aos-delay={index * 100}>
			<div className="promo-code-how-it-work-icon-box">
				<div className="promo-code-how-it-work-background-icon-box">
					<Icon className="promo-code-how-it-work-icon" />
				</div>
			</div>
			<div className="promo-code-how-it-work-content">
				<p className="promo-code-how-it-work-content-text" dangerouslySetInnerHTML={{ __html: title }} />
				{children}
			</div>

		</section>
	);
};

export default React.memo(PromoCodeHowItWorkComponent);
