import React from "react"

interface IAppShopBtnComponent {
	title: string
	to: string
	Icon: any
}

const AppShopBtnComponent = ({ Icon, to, title, }: IAppShopBtnComponent) => {

	return (
		<a
			className="app-shop-link" href={to}
			target="_blank"
			rel="noreferrer"
			title={title}
		>
			<Icon className="app-shop-icon" />
		</a>
	);
};

export default React.memo(AppShopBtnComponent);
