import React from "react"
import CopySharePromoSvg from "../../../svgs/sharePromo/CopySharePromoSvg";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";


const PromoCodeBoxComponent = () => {
	const [copyTitle, setCopyTitle] = useState("Skopiuj kod");
	const { code } = useParams();
	const handleCopy = useCallback(() => {
		navigator.clipboard.writeText(code || '');
		setCopyTitle("Kod skopiowany")
		const updateTitle = setTimeout(() => {
			setCopyTitle("Skopiuj kod")
		}, 2000)
		return (() => {
			clearTimeout(updateTitle)
		})
	}, [code])
	return (
		<section className="promo-code-box" data-aos="fade-right" data-aos-delay="500">
			<div className="container promo-code-container">
				<h4 className="text-lg">Twój kod promocyjny</h4>
				<div className="promo-code-box-code-container">
					<h3 className="promo-code-box-code">{code}</h3>
				</div>
				<button className="promo-code-box-button text-lg" onClick={handleCopy}>
					<CopySharePromoSvg className="promo-code-box-icon" />
					<h4 className="promo-code-box-copy-title">{copyTitle}</h4>
				</button>
			</div>
		</section>
	);
};

export default React.memo(PromoCodeBoxComponent);
