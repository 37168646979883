import * as React from "react"

const CopySharePromoSvg = (props) => (
	<svg
		viewBox="0 0 66 66"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M57.407 65.027H22.861c-4.179-.004-7.616-3.441-7.62-7.62V22.861c.004-4.179 3.441-7.616 7.62-7.62h34.546c4.179.004 7.616 3.441 7.62 7.62v34.546c-.004 4.179-3.441 7.616-7.62 7.62ZM22.861 20.321a2.555 2.555 0 0 0-2.54 2.54v34.546a2.555 2.555 0 0 0 2.54 2.54h34.546a2.555 2.555 0 0 0 2.54-2.54V22.861a2.555 2.555 0 0 0-2.54-2.54H22.861Zm-12.7 24.385H7.62a2.555 2.555 0 0 1-2.54-2.54V7.62a2.555 2.555 0 0 1 2.54-2.54h34.546a2.555 2.555 0 0 1 2.54 2.54v2.413h5.08V7.62C49.782 3.441 46.345.004 42.166 0H7.62C3.441.004.004 3.441 0 7.62v34.546c.004 4.179 3.441 7.616 7.62 7.62h2.54l.001-5.08Z"
			style={{
				fill: "#000000",
				fillRule: "nonzero",
			}}
		/>
	</svg>
)

export default CopySharePromoSvg
