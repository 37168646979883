import * as React from "react"

const DownloadSharePromoCodeSvg = (props) => (
	<svg
		viewBox="0 0 50 50"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M49.681 46.576a3.12 3.12 0 0 1-3.105 3.105H3.105A3.12 3.12 0 0 1 0 46.576a3.12 3.12 0 0 1 3.105-3.105h43.471a3.12 3.12 0 0 1 3.105 3.105Zm-27.036-9.837a3.1 3.1 0 0 0 4.391 0l11-11a3.105 3.105 0 0 0 .892-2.178 3.119 3.119 0 0 0-3.105-3.105c-.814 0-1.597.32-2.178.892l-5.7 5.7V3.105A3.12 3.12 0 0 0 24.84 0a3.12 3.12 0 0 0-3.105 3.105v23.942l-5.7-5.7a3.105 3.105 0 0 0-2.178-.892 3.119 3.119 0 0 0-3.105 3.105c0 .814.32 1.597.892 2.178l11.001 11.001Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
		/>
	</svg>
)

export default DownloadSharePromoCodeSvg
