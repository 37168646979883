import React from "react"
import LogoSvg from "../../../svgs/LogoSvg";
import PromoHeaderImg from "../../../imgs/promoHeader.png";

const PromoCodeHeaderComponent = () => {
	return (
		<header className="promo-code-header">
			<section className="container">
				<div className="promo-code-header-content-container">
					<div className="promo-code-header-content">
						<LogoSvg className="promo-code-header-logo" />
						<h2 className="promo-code-header-title" data-aos="fade-up">Zaproś przyjaciół</h2>
						<h1 className="promo-code-header-slogan" data-aos="fade-up" data-aos-delay="100">Zbieraj ekstra Punkty</h1>
						<p className="promo-code-header-subtitle" data-aos="fade-up" data-aos-delay="200">Zarejestruj się w Outlet Park Szczecin i zbieraj punkty, które wymienisz na nagrody. Wykorzystaj swój kod promocyjny, a pierwsze punkty dostaniesz w prezencie!</p>
					</div>
					<div className="promo-code-header-code-img-box" data-aos="fade-left" data-aos-delay="400">
						<img src={PromoHeaderImg} alt="promo header" className="promo-code-header-code-img" />
					</div>
				</div>
			</section>
		</header>
	);
};

export default React.memo(PromoCodeHeaderComponent);
