import * as React from "react"

const ScanSharePromoCodeSvg = (props) => (
	<svg
		viewBox="0 0 57 62"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M31.36 2H9.162C6.879 2 5 3.879 5 6.162V38.091a2.789 2.789 0 0 0 2.775 2.775c.377 0 .751-.077 1.097-.226l4.791-2.063 5.384 2.578a2.795 2.795 0 0 0 2.45-.028l5.7-2.887 4.3 2.175c.387.195.815.296 1.248.296a2.788 2.788 0 0 0 2.775-2.771V6.162c0-2.282-1.878-4.161-4.16-4.162Zm1.387 35.94-4.3-2.175a2.775 2.775 0 0 0-2.507 0l-5.7 2.887-5.383-2.576a2.765 2.765 0 0 0-2.293-.047L7.775 38.09V6.162c0-.761.626-1.387 1.387-1.387h22.2c.761 0 1.387.626 1.387 1.387v31.781l-.002-.003Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(7.818 9.519)"
		/>
		<path
			d="M9.081 1h22.2c2.785.004 5.076 2.296 5.079 5.081v31.778a3.712 3.712 0 0 1-3.694 3.691 3.704 3.704 0 0 1-1.662-.4l-3.887-1.966-5.286 2.677a3.715 3.715 0 0 1-3.259.038l-5.011-2.4L9.154 41.4a3.701 3.701 0 0 1-1.46.301A3.712 3.712 0 0 1 4 38.008V6.081C4.003 3.295 6.295 1.003 9.081 1Zm23.585 38.712a1.865 1.865 0 0 0 1.856-1.854V6.081a3.262 3.262 0 0 0-3.243-3.243H9.081a3.262 3.262 0 0 0-3.243 3.243V38.01c0 1.018.838 1.856 1.856 1.856.252 0 .502-.051.734-.151l5.172-2.229 5.761 2.758c.52.245 1.125.238 1.639-.02l6.113-3.1 4.716 2.385c.259.132.546.202.837.203ZM9.081 3.775h22.2a2.321 2.321 0 0 1 2.306 2.306v31.781h-.92l-.413.817-4.3-2.175a1.856 1.856 0 0 0-1.677 0l-6.106 3.092-5.791-2.772a1.84 1.84 0 0 0-1.531-.032L6.775 39.4V6.081a2.321 2.321 0 0 1 2.306-2.306Zm22.666 32.589V6.081a.471.471 0 0 0-.468-.468H9.081a.471.471 0 0 0-.468.468v30.531l3.5-1.509a3.691 3.691 0 0 1 3.058.064l4.973 2.38 5.3-2.683a3.694 3.694 0 0 1 3.337 0l2.966 1.5Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(7.899 9.6)"
		/>
		<path
			d="M25.261 8H11.387C10.626 8 10 8.626 10 9.388c0 .761.626 1.387 1.387 1.387h13.874c.761 0 1.387-.626 1.387-1.387 0-.762-.626-1.388-1.387-1.388Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(9.755 11.843)"
		/>
		<path
			d="M11.306 7H25.18a2.318 2.318 0 0 1 2.307 2.307 2.318 2.318 0 0 1-2.307 2.306H11.306A2.317 2.317 0 0 1 9 9.307 2.317 2.317 0 0 1 11.306 7ZM25.18 9.775a.47.47 0 0 0 .468-.468.47.47 0 0 0-.468-.469H11.306a.471.471 0 0 0-.469.469.47.47 0 0 0 .469.468H25.18Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(9.836 11.924)"
		/>
		<path
			d="M25.261 13H11.387c-.761 0-1.387.626-1.387 1.387 0 .762.626 1.388 1.387 1.388h13.874c.761 0 1.387-.626 1.387-1.388 0-.761-.626-1.387-1.387-1.387Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(9.755 13.78)"
		/>
		<path
			d="M11.306 12H25.18a2.318 2.318 0 0 1 2.307 2.307 2.318 2.318 0 0 1-2.307 2.306H11.306A2.317 2.317 0 0 1 9 14.307 2.317 2.317 0 0 1 11.306 12Zm13.874 2.775a.47.47 0 0 0 .468-.468.47.47 0 0 0-.468-.469H11.306a.471.471 0 0 0-.469.469.47.47 0 0 0 .469.468H25.18Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(9.836 13.861)"
		/>
		<path
			d="M19.191 18h-7.8c-.761 0-1.388.626-1.388 1.388 0 .761.627 1.387 1.388 1.387h7.8c.761 0 1.387-.626 1.387-1.387 0-.762-.626-1.388-1.387-1.388Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(9.755 15.717)"
		/>
		<path
			d="M11.306 17h7.8a2.317 2.317 0 0 1 2.306 2.307 2.317 2.317 0 0 1-2.306 2.306h-7.8a2.318 2.318 0 0 1-2.307-2.306A2.318 2.318 0 0 1 11.306 17Zm7.8 2.775a.47.47 0 0 0 .468-.468.47.47 0 0 0-.468-.469h-7.8a.47.47 0 0 0-.468.469.47.47 0 0 0 .468.468h7.8Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(9.836 15.798)"
		/>
		<path
			d="M53.77 61.08h-10.4a2.31 2.31 0 0 1-2.417-2.297 2.31 2.31 0 0 1 2.417-2.297h8.252v-8.248a2.31 2.31 0 0 1 2.297-2.183 2.31 2.31 0 0 1 2.297 2.183v10.55a2.31 2.31 0 0 1-2.3 2.3l-.146-.008Zm-51.473 0A2.31 2.31 0 0 1 0 58.847V48.238a2.31 2.31 0 0 1 2.3-2.3 2.31 2.31 0 0 1 2.3 2.3v8.253h8.252a2.31 2.31 0 0 1 2.183 2.297 2.31 2.31 0 0 1-2.183 2.297L2.297 61.08Zm49.322-48.238V4.594h-8.252a2.31 2.31 0 0 1-2.417-2.297A2.31 2.31 0 0 1 43.367 0h10.549a2.31 2.31 0 0 1 2.3 2.3v10.542a2.31 2.31 0 0 1-2.297 2.183 2.31 2.31 0 0 1-2.297-2.183h-.003ZM0 12.842V2.238A2.31 2.31 0 0 1 2.3 0h10.546a2.31 2.31 0 0 1 2.183 2.297 2.31 2.31 0 0 1-2.183 2.297H4.594v8.248a2.31 2.31 0 0 1-2.3 2.3 2.31 2.31 0 0 1-2.3-2.3H0Z"
			style={{
				fill: "#000",
				fillRule: "nonzero",
			}}
			transform="translate(.006 .003)"
		/>
	</svg>
)

export default ScanSharePromoCodeSvg
